<template>
  <v-dialog
    scrollable
    v-model="dialog"
    max-width="600px"
    @click:outside="resetForm()"
  >
    <v-card>
      <v-card-title class="headline"
        >{{ isEditing ? "Edit" : "Add a new" }} Enquiry
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-form @submit.prevent="saveForm" method="post" id="enquiry-form">
          <v-text-field
            label="Name*"
            v-model="fields.name"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('name')"
            :error-messages="errors['name']"
          ></v-text-field>

          <v-text-field
            label="Email"
            v-model="fields.email"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('email')"
            :error-messages="errors['email']"
          ></v-text-field>

          <v-text-field
            label="Company"
            v-model="fields.company"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('company')"
            :error-messages="errors['company']"
          ></v-text-field>

          <v-text-field
            label="Phone"
            v-model="fields.phone"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('phone')"
            :error-messages="errors['phone']"
          ></v-text-field>

          <v-textarea
            label="Comments"
            v-model="fields.comments"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('comments')"
            :error-messages="errors['comments']"
          ></v-textarea>

          <v-text-field
            label="How Many Binds"
            v-model="fields.how_many_blinds"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('how_many_blinds')"
            :error-messages="errors['how_many_blinds']"
          ></v-text-field>

          <v-select
            label="Fabric"
            v-model="fields.fabric"
            :items="fabrics"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('fabric')"
            :error-messages="errors['fabric']"
          ></v-select>

          <v-select
            label="How Many Sides"
            v-model="fields.how_many_sides"
            :items="sides"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('how_many_sides')"
            :error-messages="errors['how_many_sides']"
          ></v-select>

          <v-select
            label="Orientation"
            v-model="fields.orientation"
            :items="orientations"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('orientation')"
            :error-messages="errors['orientation']"
          ></v-select>

          <v-select
            label="Motorisation"
            v-model="fields.motorisation"
            :items="motorisations"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('motorisation')"
            :error-messages="errors['motorisation']"
          ></v-select>

          <v-text-field
            label="Hex/Pantone/RAL Colour"
            v-model="fields.background"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('background')"
            :error-messages="errors['background']"
          ></v-text-field>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="resetForm()">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          form="enquiry-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  components: {},

  data() {
    return {
      fields: {
        name: null,
        email: null,
        company: null,
        phone: null,
        comments: null,
        background: null,
        how_many_blinds: null,
        fabric: null,
        how_many_sides: null,
        orientation: null,
        motorisation: null,
      },
      fabrics: ["Standard White", "Premium White", "Blackout Fabric"],
      statuses: [
        "For Sale",
        "Under Offer",
        "Sold STC",
        "STCM",
        "Reserved",
        "Complete",
      ],
      sides: ["One side", "Both sides"],
      orientations: ["Facing Outside", "Facing Inside"],
      motorisations: ["Motorised", "Not Motorised"],
      errors: {},
      dialog: false,
      loading: false,
      isEditing: false,
    };
  },

  methods: {
    heroImageHasChanged() {
      this.fields.hereo_image_has_changed = true;
    },

    openForm: function (enquiry = null) {
      if (enquiry !== null) {
        this.enquiry = enquiry;
        this.fields.name = enquiry.name;
        this.fields.email = enquiry.email;
        this.fields.company = enquiry.company;
        this.fields.phone = enquiry.phone;
        this.fields.comments = enquiry.comments;
        this.fields.background = enquiry.background;
        this.fields.how_many_blinds = enquiry.how_many_blinds;
        this.fields.fabric = enquiry.fabric;
        this.fields.how_many_sides = enquiry.how_many_sides;
        this.fields.orientation = enquiry.orientation;
        this.fields.motorisation = enquiry.motorisation;

        this.isEditing = true;
      }
      this.dialog = true;
    },

    saveForm() {
      this.loading = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        isEditing: this.isEditing,
        fields: this.fields,
      };

      if (this.isEditing) {
        payload.enquiryId = this.$route.params.enquiryId;
      }

      this.$store
        .dispatch("lpb/enquiries/saveEnquiry", payload)
        .then(() => {
          this.resetForm();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },

    resetForm: function () {
      this.dialog = false;
      this.loading = false;
      this.isEditing = false;
      this.fields.name = null;
      this.fields.hereo_image_has_changed = false;
      this.fields.status = null;
      this.fields.slug = null;
      this.fields.hero_heading = null;
      this.fields.hero_text = null;
      this.fields.benefits_hading = null;
      this.fields.benefits_paragraph = null;
      this.fields.features_text = null;
    },
  },
};
</script>
